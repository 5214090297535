.Cart {
  position: relative;
}

.PopupCart {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 13, 37, 0.15);
    border-radius: 8px;
    width: 520px;
    right: 0;
    top: 100%;
    z-index: 20;
    height: inherit!important;
}

.Items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}
.Item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;
}

.ItemPhoto {
    max-width: 150px;
}

.Name {
    display: flex;
    color: #333;
    gap: 5px;
    font-size: 15px;
    flex-direction: column;
    flex: 1;
}


.Photo {
    color: #282f36;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    min-width: 60px;
}

.Price {
    font-weight: bold;
}

.Qty {
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    width: 142px;
    height: 48px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
        display: flex
    }

div {
        display: flex;
        cursor: pointer;
        height: 100%;
        align-items: center;
        padding: 10px;
        justify-content: center;

    }
}

.Total {
    border-top: 1px solid #dedfe2;
    padding: 15px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.TotalItem {
    display: flex;
    justify-content: space-between;
}

.TotalItemFinal {
    font-size: 20px;
}

.Delete {
    cursor: pointer;
}


.CartMobileButton {
    display: none;
}

.CartIconWrapper {
    position: relative;

    span {
        position: absolute;
        background-color: #ffa200;
        right: -10px;
        top: -8px;
        width: 20px;
        border-radius: 50%;
        font-size: 13px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.CartIcon {
    stroke: #fff;
    stroke-width: 2px;
}

.CartMobileIcon {
    stroke: var(--main-theme);
    stroke-width: 2px;
}

.Bottom {
    display: flex;
    gap: 15px;
    > div {
        flex: 1
    }
}

@media only screen and (max-width: 991px) {
    .Cart {
        display: none;
    }

    .CartMobileButton {
        display: flex;
    }
}