.CatalogButton {

    &Scrolled {
        max-height: 35px;
    }
}


@media only screen and (max-width: 991px) {
    .CatalogButton {
        display: none;
    }
}