.FeedBack {
    display: flex;
    bottom: 50px;
    right: 50px;
    position: fixed;
    background-color: #ffa500;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);

    a {
        width: 60px;
        height: 60px;
    }

    &:hover {
        span {
            opacity: 1;
            visibility: visible;
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #033DD5;
            border-color: #033DD5;

        }
    }
    &:active{
        background-color: #0634B0;
        border-color: #0634B0
    }

    &__Wrapper {
        z-index: 100;
        background-color: #00000061;
        position: fixed;
        width: 100%;
        left: 0;
        height: 100%;
        top: 0;

        > div {
            display: flex;
            bottom: 50px;
            right: 50px;
            position: fixed;
            flex-direction: column;
            gap: 15px;
       

            > div {
                &:hover {
                    span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                position: relative;
                cursor: pointer;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                > a {
                    width: 100%;
                    height: 100%;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    &__Backcall {
        background-color: #ffa500;
    }
    &__Close {
        background-color: #1247a5!important;
    }
}


.ScrollTop {
    position: fixed;
    bottom: 60px;
    right: 50px;
    background-color: var(--main-theme);
    border-radius: 50%;
    z-index: 10;
    width: 60px;
    height: 60px;
    cursor: pointer;
    svg {
        fill: #fff;
    }
}


.Info {
    width: 135px;
    background-color: #fff;
    color: #3c3a3a;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 18px;
    position: absolute;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    left: -150px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.Telegram {
    display: flex;
    bottom: 50px;
    right: 50px;
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);

    div {
        position: relative;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        > a {
            width: 100%;
            height: 100%;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .Telegram {
        bottom: 15px;
        right: 15px;
    }
    .ScrollTop {
        bottom: 90px;
        right: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .FeedBack {
        bottom: 25px;
        right: 15px;

        &__Wrapper {
            > div {
                bottom: 25px;
                right: 15px;
                span {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}