.Button {
  min-width: 100px;
  max-width: 180px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ButtonMobile {
    display: none;
}

@media only screen and (max-width: 991px) {
  .Button {
    display: none;
  }
  .ButtonMobile {
    display: flex;
  }
}