.Header {
    position: fixed;
    width: 100%;
    display: flex;
    z-index: 20;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;

    background-color: #fff;

    &Wpapper {
        height: 137px;
    }
}

.Top {
    width: 100%;
    border-bottom: 1px solid #E1E2E5;
    transition: 0.3s;
    >div {
        display: flex;
        font-size: 14px;

        justify-content: space-between;
        height: 44px;
        transition: 0.3s;
        align-items: center;
        gap:20px;
    }

    &Left {
        display: flex;
    }


    &Scrolled {
        padding: 0;

        > div {
            height: 25px;
            font-size: 12px;

        }
        .Location {

            span {
                bottom: -4px;
                font-size: 10px;
            }
        }

    }
}

.Body {
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 16px 0;
    top: 0;
    background-color: #fff;
    transition: 0.3s;

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        gap: 20px;
    }
}

.BodyMobileMenu {

}

.BodyWrapper {
    width: 100%;
}

.BodyFixed {
    padding: 0;


    @media only screen and (min-width: 991px) {
        .Logo {
            img {
                max-width: 150px;
            }
        }
    }
}

.Logo {
    max-width: 200px;
    margin-right: 60px;
    padding-top: 5px;
    transition: 0.3s;

    img {
        transition: 0.3s;
    }
}

.Location {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
        color: #828282;
        margin-left: 10px
    }

    svg {
        margin: 3px 0 0 7px;
    }
}

.City {
    color: #282f36;
    position: relative;
}

.Phone {
    display: flex;
    align-items: center;
    margin-left: 20px;

    a {
        color: #282f36;
        font-weight: 600;
    }

    > div {
        line-height: 20px;
        font-size: 15px;
        color: var(--main-theme);
        margin-left: 20px;
    }
}

.Personal {
    line-height: 22px;
    font-size: 17px;
    color: #282f36;
}


.DetectedPopup {
    padding: 50px 60px;
    background-color: #fff;
    border-radius: 8px;
    animation-name: zoomIn;
    animation-duration: .3s;
    animation-fill-mode: both;
    max-height: 100%;
    overflow: auto;
    text-align: center;
    background: #e0e9ff;

    h2 {
        font-size: 28px;
        margin-bottom: 25px;
    }
}

.DetectedButtons {
    display: flex;
    margin-top: 20px;
    gap: 15px;
    justify-content: center;

    > div {
        min-width: 120px;
    }
}

.DetectedCity {
    font-size: 20px;
}

.Menu {
    display: none;
}



.MobileCatalog {
}

.Icons {
    display: flex;
    gap: 20px;
    flex: 1;
    transition: 0.3s;
    align-items: center;


    @media only screen and (min-width: 991px) {
        &Scrolled {
            height: 35px;

            > div > div {
                height: 35px;
            }
        }
    }

    &Mobile {
        display: none;
    }
}

.IconsMenuActive {
    opacity: 0;
    pointer-events: none;
}

.ButtonMobile {


    &Active {
        opacity: 0;
        pointer-events: none;
        opacity: 0;
    }
}

.Wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .15s linear;
    background-color: #000c;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    animation-duration: .3s;
    padding: 20px;
}

.Socs {
    display: none;
}

@media (max-width: 1350px) and (min-width: 992px) {
    .CityWithDeliveryInfo {
        margin-top: -10px;
    }
    .Location > span {
        position: absolute;
        width: 200px;
        bottom: -12px;
        font-size: 12px;
        left: 0;
        margin: 0;
    }
}

@media (max-width: 1050px) {
    .TopLeft {
        font-size: 13px;
    }
}

@media only screen and (max-width: 991px) {
    .City {
        top: 2px;
    }
    .CityWithDeliveryInfo {
        margin-top: -7px;
    }
    .Phone {
        margin-left: 10px;
    }
    .Socs {
        display: flex;
        gap: 15px;
        > a {
            width: 100%;
            height: 100%;
            display: flex;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .HeaderWpapper {
        height: 80px;
    }
    .Body {
        > div {
            height: 40px;
        }
    }
    .Links {
        display: none;
    }

    .Top {
        border-bottom: none;
        background-color: #e0e9ff;
        height: 30px;
        > div {
            height: 30px;
        }

        &Mobiled {
            opacity: 0;
            height: 0;
            z-index: -1;
        }
    }

    .TopLeft {
        width: 100%;
        justify-content: space-between;
    }

    .Logo {
        max-width: 120px;
        margin: 0;
    }

    .IconsMobile {
        display: flex;
    }

    .Menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        height: 100%;

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 4px;
        }

        span {
            background-color: #333;
            width: 24px;
            height: 3px;
            border-radius: 2px;
            transition: 0.3s;
        }

        &Active {
            span:first-child {
                transform: rotate(45deg);
            }
            span:nth-child(2) {
                display: none;
            }
            span:last-child {
                transform: rotate(-45deg);
                margin-top: -7px;
            }
        }
    }

    .Body {
        padding: 5px 0;
    }

    .MobileCatalog {

        background-color: #0F1323;
        width: 100%;
        color: #fff;

        > div {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .Icons {
        flex: 0;
    }

    .BodyWrapper {
        height: 50px;
    }
    .Location {
        flex: 1;
        margin-top: -8px;
        svg {
        }
        span {
            position: absolute;
            margin: 0;
            bottom: -2px!important;
            font-size: 11px!important;
        }
    }
}


@media (max-width: 500px) {
    .TopLeft {
        font-size: 12px;
    }
    .Region {
        margin-top: -10px;
    }

}