.BackForm {
    position: fixed;
    top: 235px;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 5px 60px rgba(36, 40, 43, 0.1);
}

.Show {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    cursor: pointer;
    box-shadow: 5px 5px 60px rgba(36, 40, 43, 0.1);
    svg {
        fill: none;
        stroke: #2F69FF;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}

.Close {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    cursor: pointer;
}

.Items {
    box-shadow: 5px 5px 60px rgba(36, 40, 43, 0.1);
}

.Item {
    width: 100%;
    height: 50px;
    position: relative;
    cursor: pointer;

    > div {
        position: absolute;
        background-color: #fff;
        display: flex;
        right: 0;
        transition: 0.3s;

        > div {

        }

        span {
            white-space: nowrap;
            display: flex;
            align-items: center;
            padding: 0 10px;
        }
    }

    &:nth-child(1) {
        svg {
            fill: #2f69ff !important;
        }
    }
    &:nth-child(2) {
        svg {
            //stroke: #2f69ff!important;
        }
    }
    &:nth-child(3) {
        svg {
            //fill: #2f69ff!important;
        }
    }
    &:nth-child(4) {
        svg {
            fill: #5382b8 !important;
        }
    }
    &:nth-child(5) {
        svg {
            fill: #924aff !important;
        }
    }
    &:nth-child(6) {
        svg {
            fill: #30ad80 !important;
        }
    }
    &:nth-child(7) {
        svg {
            fill: #ef483d !important;
        }
    }

    @media only screen and (min-width: 992px) {


        &:hover {
            > div {
                right: inherit;
                color: #fff;
                right: inherit;
                border-radius: 0 5px 5px 0;
            }

            svg {
                fill: #fff !important;
            }
        }

        &:nth-child(1):hover {
            > div {
                background-color: #2f69ff;
            }
        }
        &:nth-child(2):hover {
            > div {
                background-color: #589DD2;
            }
        }
        &:nth-child(3):hover {
            > div {
                background-color: #25D366;
            }
        }
        &:nth-child(4):hover {
            > div {
                background-color: #5382b8;
            }
        }
        &:nth-child(5):hover {
            > div {
                background-color: #924aff;
            }
        }
        &:nth-child(6):hover {
            > div {
                background-color: #30ad80;
            }
        }
        &:nth-child(7):hover {
            > div {
                background-color: #ef483d;
            }
        }
    }
}


.Cart {
    width: 100%;
    height: 50px;
    position: relative;
    cursor: pointer;

    > div {
        background-color: #fff;
        display: flex;
        right: 0;
        transition: 0.3s;
        position: relative;

        > div {

        }

        span {
            white-space: nowrap;
            display: flex!important;
            align-items: center;
            padding: 0 10px;
        }
    }

    svg {
    }
}

.Icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    z-index: 10;
    position: relative;

    span {
        position: absolute;
        background-color: #ffa200;
        right: 0px;
        top: 0px;
        width: 20px;
        border-radius: 50%;
        font-size: 13px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-height: 840px) {
    .BackForm {
        top: 100px
    }
}


@media only screen and (max-width: 991px) {
    .BackForm {
        bottom: 0;
        top: inherit;
        width: 100%;
        background-color: #fff;
    }

    .Close {
        display: none;
    }

    .Items {
        display: flex;

        span {
            display: none!important;
        }
    }

    .Item {
        display: flex;
        justify-content: center;

        &:nth-child(4) {
            svg {
                margin-top: 5px;
            }
        }

        > div {
            position: initial;
        }
    }

    .Cart {
        > div {
            display: flex;
            justify-content: center;
            width: 100%;


            span {
                top: 5px;
                right: 5px;
            }
        }
    }
}